import { BrowserTracing, Integrations } from '@sentry/tracing';

/**
 * Regex matching all subdomains everlywell.com
 * https://regex101.com/r/DUYBit/1
 */

const allowedUrls: RegExp[] = [
  /(https?:\/\/)?(([a-z0-9]+(?:[a-z0-9-]*[a-z0-9])?[.])*)everlywell.com\/?(admin\/*)?/i,
];

// https://docs.sentry.io/platforms/javascript/guides/react/configuration/filtering/#decluttering-sentry
const deniedUrls: RegExp[] = [
  // Sentry standard denied URLs - browser extensions, facebook...
  /graph\.facebook\.com/i,
  /connect\.facebook\.net\/en_US\/all\.js/i,
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  /extensions\//i,
  /^chrome:\/\//i,
  /127\.0\.0\.1:4001\/isrunning/i,
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  // EH denied URLs
  /analytics\.tiktok\.com/i,
  /api\.levelaccess\.net/i,
  /cdn\.noibu\.com/i,
  /cypress:3001/i,
  /extole\//i,
  /google\.com/i,
  /googletagmanager\.com/i,
  /jst\.ai/i,
  /launchdarkly\.com/i,
  /mixpanel\.com/i,
  /solvvy\.com/i,
  /tiktok\.com/i,
];

const ignoredErrors: string[] = [
  "Can't find variable: getCookie",
  "Can't find variable: offset",
  'A network error occurred.',
  'Network Error',
  'Non-Error promise rejection captured with keys: [object has no keys]',
  'Non-Error promise rejection captured with keys: currentTarget, detail, isTrusted, target',
  'Non-Error promise rejection captured with keys: data, sentMessage, stack',
  'Non-Error promise rejection captured with keys: message, name, originalError, requestUrl',
  'Non-Error promise rejection captured with value: JSBRIDGE TIMEOUT',
  'Non-Error promise rejection captured with value: undefined',
  'fbq is not defined',
  'function Error() { [native code] }',
  'getCookie is not defined',
];

// initialized tracing in prod only
const performanceTracing =
  process.env.REACT_APP_ENV === 'production'
    ? [new BrowserTracing({ traceXHR: false }), new Integrations.GraphQL()]
    : undefined;

export { allowedUrls, deniedUrls, ignoredErrors, performanceTracing };
