import * as Sentry from '@sentry/gatsby';
import { Event } from '@sentry/gatsby/types';

import pjson from './package.json';
import {
  allowedUrls,
  deniedUrls,
  ignoredErrors,
  performanceTracing,
} from './src/utils/sentry/sentryUrls';

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  denyUrls: deniedUrls,
  allowUrls: allowedUrls,
  ignoreErrors: ignoredErrors,
  environment: process.env.REACT_APP_ENV,
  release: pjson.version, // match release with app version and env
  integrations: performanceTracing,
  tracesSampleRate: 0.2,
  beforeSend(event: Event) {
    const eventUrl: string | undefined = event.request?.url;
    const eventIp: string | undefined = event.user?.ip_address;

    if (
      deniedUrls.some((url) => url.test(eventUrl as string)) ||
      eventIp === '18.207.82.28' // cypress:3001 IP address
    ) {
      return null;
    } else {
      return event;
    }
  },
});
